
<mat-dialog-content class="mat-typography">
  <div class="container">
    <main class="form-signin">

      <div class="col-md-12">

        <form [formGroup]="form" (ngSubmit)="update()" >
          <div class="row g-3">

            <div class="col-sm-6">
              <label for="firstName" class="form-label">Producto</label>
              <input readonly [formControl]="form.controls['product']"  type="text" class="form-control" id="firstName" placeholder="" value="" required="">

            </div>

            <div class="col-sm-6">
              <label for="lastName" class="form-label">Precio</label>
              <input readonly [formControl]="form.controls['price']" type="text" class="form-control" id="lastName" placeholder="" value="" required="">

            </div>

            <div class="col-sm-6">
              <label for="cedula" class="form-label">Frecuencia de cobro</label>
              <input  readonly [formControl]="form.controls['frecuency']" type="text" class="form-control" id="cedula" placeholder="" value="" required="">

            </div>

            <div class="col-sm-6">
              <label for="email" class="form-label">Próximo cobro</label>
              <input readonly [formControl]="form.controls['next']" type="text" class="form-control" id="email" placeholder="" value="" required="">

            </div>

            <hr>

            <a style="text-decoration-line: underline; cursor: pointer;" (click)="addressesPopUp()" >( Cambiar direccion de envío )  </a>

            <div class="col-sm-4">
              <label for="lastName" class="form-label">Provincia</label>
              <input readonly [formControl]="form.controls['province']" type="text" class="form-control" id="lastName" placeholder="" value="" required="">

            </div>

            <div class="col-sm-4">
              <label for="cedula" class="form-label">Corregimiento</label>
              <input  readonly [formControl]="form.controls['corregimiento']" type="text" class="form-control" id="cedula" placeholder="" value="" required="">

            </div>

            <div class="col-sm-4">
              <label for="email" class="form-label">Dirección</label>
              <input readonly [formControl]="form.controls['adress']" type="text" class="form-control" id="email" placeholder="" value="" required="">

            </div>

            <hr>
            <a style="text-decoration-line: underline; cursor: pointer;" (click)="tdcPopUp()" > ( Cambiar tarjeta ) </a>

            <div class="col-sm-6">
              <label for="cedula" class="form-label">Titular</label>
              <input  readonly [formControl]="form.controls['number_tdc']" type="text" class="form-control" id="cedula" placeholder="" value="" required="">

            </div>

            <div class="col-sm-6">
              <label for="email" class="form-label">Tarjeta</label>
              <input readonly [formControl]="form.controls['name_tdc']" type="text" class="form-control" id="email" placeholder="" value="" required="">

            </div>



          </div>

        </form>

        <div *ngIf="data.suscription.status == 3" style="text-align: end;">
          <a class="text-dark" type="button" style="cursor: pointer;" (click)="validatePopup('Activar', 1)" > Activar suscripcion</a>
        </div>

        <div *ngIf="data.suscription.status == 1" style="text-align: end;">
          <a style="display: block;" class="text-dark" type="button" style="cursor: pointer;" (click)="validatePopup('Pausar', 2) "  > Pausar suscripcion</a>
        </div>
        <div *ngIf="data.suscription.status != 2" style="text-align: end;">
          <a class="text-dark" type="button" style="cursor: pointer;" (click)="validatePopup('Cancelar', 3)"  > Cancelar suscripcion</a>
        </div>


      </div>
    </main>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="center">
  <button style="margin-top: 30px;margin-right: 20px; width: 14%;"  class="btn btn-primary btn-xl "   (click)="dialogRef.close(4)" >Volver</button>
  <button style="margin-top: 30px;" (click)="update()" class="btn btn-primary btn-xl " >Actualizar</button>
</mat-dialog-actions>
