<!-- Navigation-->
<app-menu [navigation]="aloudNavigation"></app-menu>


<div id="page-top" [class]="class">
  <!-- Masthead-->
  <header class="masthead" id="inicio">
    <div class="container">
      <div *ngIf="isSuscriptionActive" class="masthead-heading">{{ title }}</div>
      <div *ngIf="!isSuscriptionActive" class="masthead-heading">PORTAL EN MANTENIMIENTO</div>
      <img style="width: 100%" [src]="getImagePrincipal()" />
      <p class="sites-description">{{ description }}</p>
      <a *ngIf="aloudNavigation"  class="btn btn-primary btn-xl" href="#planes">Ver Planes</a>
    </div>
  </header>
    <!-- Planes Grid-->
    <section *ngIf="isSuscriptionActive" class="page-section prices" id="planes">
      <div class="container">
        <div class="text-center">
          <h3 class="section-subheading text-muted">Planes</h3>
        </div>
        <app-plan [navigation]="aloudNavigation" ></app-plan>
      </div>
    </section>
    <!-- Banner-->
    <section *ngIf="isSuscriptionActive" class="page-section" id="beneficios">
      <div class="container">
        <h1>{{ banner_title }}</h1>
        <div class="text-center">
          <div class="row justify-content-center" style="width: 100%">
            <div class="col-lg-4 benefits">
              <!-- <div class="number-benefits">1</div> -->
              <h4>{{ title }}</h4>
              <p>{{ banner_description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</div>

<app-footer></app-footer>
