import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { HomeComponent } from './views/home/home.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { MenuComponent } from './components/menu/menu.component';
import { PlanComponent } from './components/plan/plan.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './views/login/login.component';
import { PortfolioModalsComponent } from './components/portfolio-modals/portfolio-modals.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {  MatTableModule } from '@angular/material/table';
import {TokenInterceptor} from "./interceptors/token.interceptor";
import { AddressComponent } from './components/address/address.component';
import { FooterComponent } from './components/footer/footer.component';
import { PaymentComponent } from './components/payment/payment.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AddressesComponent } from './views/addresses/addresses.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ForgotComponent } from './views/forgot/forgot.component';
import { PaymentsComponent } from './views/payments/payments.component';
import { ProfileComponent } from './views/profile/profile.component';
import { SingupComponent } from './views/singup/singup.component';
import { SuscriptionsComponent } from './views/suscriptions/suscriptions.component';
import { AddressPopupComponent } from './views/addresses/address-popup/address-popup.component';
import { CreateComponent } from './views/addresses/create/create.component';
import { UpdateComponent } from './views/addresses/update/update.component';
import { FacPopupComponent } from './views/checkout/fac-popup/fac-popup.component';
import { CreatePaymentComponent } from './views/payments/create-payment/create-payment.component';
import { PaymentsPopupComponent } from './views/payments/payments-popup/payments-popup.component';
import { UpdatePaymentComponent } from './views/payments/update-payment/update-payment.component';
import { SuscriptionComponent } from './views/suscriptions/suscription/suscription.component';
import { UpdateSuscriptionPopupComponent } from './views/suscriptions/update-suscription-popup/update-suscription-popup.component';
import { ValidatePopupComponent } from './views/suscriptions/update-suscription-popup/validate-popup/validate-popup.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginPopupComponent } from './views/checkout/login-popup/login-popup.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CheckoutComponent,
    MenuComponent,
    PlanComponent,
    LoginComponent,
    PortfolioModalsComponent,
    AddressComponent,
    FooterComponent,
    PaymentComponent,
    SideMenuComponent,
    AddressesComponent,
    ChangePasswordComponent,
    ForgotComponent,
    PaymentsComponent,
    ProfileComponent,
    SingupComponent,
    SuscriptionsComponent,
    AddressPopupComponent,
    CreateComponent,
    UpdateComponent,
    FacPopupComponent,
    CreatePaymentComponent,
    PaymentsPopupComponent,
    UpdatePaymentComponent,
    SuscriptionComponent,
    UpdateSuscriptionPopupComponent,
    ValidatePopupComponent,
    LoginPopupComponent
  ],
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    Ng2TelInputModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot({timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true}), // ToastrModule added
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },{ provide: MAT_DIALOG_DATA, useValue: {} },
  { provide: MatDialogRef, useValue: {} }],
  bootstrap: [AppComponent]
})
export class AppModule { }
