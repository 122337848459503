<h2 mat-dialog-title>Actualizar dirección</h2>
<mat-dialog-content class="mat-typography">

  <form [formGroup]="form" >
    <div class="row g-3">

      <div class="col-sm-6">

        <label for="firstName" class="form-label">Provincia</label>
        <select  (change)="onProvinceChange()" [formControl]="form.controls['province']" class="form-control" name="mes-exp" id="mes-exp">
          <option disabled="" selected="" value="">Elije una opción</option>
          <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>

        </select>
        <small
          *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched"
          class="form-error-msg"> Provincia es requerido </small>

      </div>

      <div class="col-sm-6">
        <label for="lastName" class="form-label">Corregimiento</label>
        <select [formControl]="form.controls['corregimiento']"  class="form-control" name="ano-exp" id="ano-exp">
          <option disabled="" selected="" value="">Elije una opción</option>
          <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>

        </select>

        <small
          *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched"
          class="form-error-msg"> Corregimiento es requerido </small>
      </div>

      <div class="col-sm-12">
        <label for="cedula" class="form-label">Dirección</label>
        <input  [formControl]="form.controls['adress']" type="text" class="form-control one-c" name="direccion" access="false" id="direccion" required="required" aria-required="true">
        <small
          *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched"
          class="form-error-msg"> Dirección es requerido </small>
      </div>


      <!-- <div class="col-sm-4">
          <label for="phone" class="form-label">Teléfono</label>
           <input type="text" class="form-control" id="phone" placeholder="" value="" required="">

          <input style="color:black" style="width: 70%; margin-left: 85px; padding-left: 0px;"  (hasError)="hasError($event)"  (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event)" (ng2TelOutput)="getNumber($event)" [ng2TelInputOptions]="{initialCountry: 'pa',separateDialCode:true}" type="text" ng2TelInput />

          <small
          *ngIf="!errorNumber"
          class="form-error-msg"> Número de teléfono inválido </small>


      </div> -->

    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Volver</button>
  <button mat-button (click)="update()" cdkFocusInitial>Actualizar</button>
</mat-dialog-actions>
