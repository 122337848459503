<h1 matDialogTitle>Confirmación</h1>
<mat-dialog-content class="mat-typography">
  <p>¿Está seguro que desea {{ data.action }} la suscripción?</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button style="margin-right: 20px;"  type="button" (click)="dialogRef.close(false)" >NO</button>

  <button mat-raised-button class="btn yellow" (click)="dialogRef.close(true)" >SI</button>
</mat-dialog-actions>
