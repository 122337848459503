<!-- Navigation-->
<app-menu></app-menu>

<div id="page-top" [class]="class">
  <main class="d-flex flex-nowrap ">
    <div class="side-bar-m bg-side d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style="width: 280px;">
      <hr>

      <app-side-menu [option] = "2" ></app-side-menu>

      <hr>

    </div>
    <div class="b-example-divider b-example-vr"></div>
    <div class="container p-account">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h4 class="m-0">Métodos de pago</h4>
          <a (click) = "add()" style="float: right; cursor: pointer;"> Agregar nueva <span>+</span></a>
        </div>
        <div class="card-body">

          <app-payment (manageStatus)="changeStatus($event)"  *ngFor=" let tarjeta of tdc " [tdc] = "tarjeta" ></app-payment>

        </div>
      </div>
    </div>
  </main>


</div>

