<ul class="nav nav-pills flex-column mb-auto">
  <li class="nav-item">
    <a href="/suscriptions"  [ngClass] = "{ active : suscription }"  class=" nav-link text-white " aria-current="page">
      Mis suscripciones
    </a>
  </li>
  <li>
    <a href="/payments" [ngClass] = "{ active : payment }" class=" nav-link text-white">
      Mis tarjetas
    </a>
  </li>
  <li>
    <a href="/addresses"  [ngClass] = "{ active : address }" class=" nav-link text-white">
      Mis direcciones
    </a>
  </li>
  <li>
    <a href="/change-password"  [ngClass] = "{ active : change }" class=" nav-link text-white">
      Cambiar contraseña
    </a>
  </li>
</ul>
