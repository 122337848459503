import { Injectable } from "@angular/core";
import {AuthService} from '../services/auth/auth.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import {ToastService} from "../services/notification/toast.service";
//import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private toast : ToastService,private router: Router, private auth : AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.auth.isLoggedIn()) { // LO DEJAMOS AVANZAR SI ESTA LOGEADO
      return true;
    } else { // CASO CONTRARIO LO MANDAMOS A INICIAR SESION NUEVAMENTE
      if(route.data.checkout !== undefined){
        this.toast.showInfo("Por favor inicia sesion para proceder con la compra");
        this.router.navigate(["/login"], {
          queryParams: {
            checkout : true
          }
        });
      }else{
        this.toast.showInfo("Por favor inicie sesion...");
        this.router.navigate(["/login"], {
          queryParams: {
            return: state.url
          }
        });
      }

      return false;
    }

  }

}
