import { EventEmitter, Injectable } from '@angular/core';
import { Plan } from 'src/app/components/plan/model/plan.model';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  public planSelected = new EventEmitter<Plan>();

  public obtainPlans = new EventEmitter<Plan[]>();

  public isSuscriptionActive = new EventEmitter<Boolean>();

  constructor() { }

  setPlanSelected( id : Number){
    localStorage.setItem("plan" , id.toString() );
  }
  getPlanSelected() : Number{
    return Number(localStorage.getItem("plan"));
  }

  setIsActive(active : boolean){
    localStorage.setItem("isActive" , active.toString());
  }

  getIsActive() : string{
    return localStorage.getItem("isActive");
  }

}
