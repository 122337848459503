import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  provinces: any[]= [] ;
  form: any ;
  corregimientos: any[]= [] ;

  constructor(private auth : AuthService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<UpdateComponent>) {
    this.spinner.open();

    this.form = new FormGroup({
      id: new FormControl(this.data.item.id, Validators.required),
      //lastname: new FormControl('', Validators.required),
      //name: new FormControl('', Validators.required),
      //phone: new FormControl('', Validators.required),
      adress: new FormControl(this.data.item.address, Validators.required),
      province : new FormControl(this.data.item.province, Validators.required),
      corregimiento : new FormControl(this.data.item.corregimiento, Validators.required)
    });

    this.webService.get(this.webService.HOST + "/province").subscribe( provinces =>{
      this.spinner.close();
      this.provinces = provinces.result;
      this.onProvinceChange();
    }, err =>{
      this.spinner.close()
      this.toast.showError(err);
    });

  }

  ngOnInit(): void {

  }

  onProvinceChange(){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == Number(this.form.value.province) )].corregimientos;

  }


  update(){

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario")
    }

    this.spinner.open();
    this.form.value.customer = { id: this.auth.getCustomer() } ;
    // this.form.value.status = 1;
    this.form.value.province = {id: Number(this.form.value.province) };
    this.form.value.corregimiento = {id: Number(this.form.value.corregimiento) }; // ESTO ESTA FIJO

    this.webService.put(this.form.value, this.webService.HOST + "/adress/" + this.form.value.id ).subscribe(res =>{
      this.toast.showSuccess("Actualizada correctamente");
      this.close();
    }, err=>{
      this.spinner.close();
      this.toast.showError(err);
    })

  }

  close(){
    this.dialogRef.close();
  }

}
