import { Component, OnInit, Input } from '@angular/core';
import { SuscriptionsService } from '../suscriptions.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UpdateSuscriptionPopupComponent } from '../update-suscription-popup/update-suscription-popup.component';
import { ToastService } from 'src/app/services/notification/toast.service';

@Component({
  selector: 'app-suscription',
  templateUrl: './suscription.component.html',
  styleUrls: ['./suscription.component.css']
})
export class SuscriptionComponent implements OnInit {


  @Input() plan : { name: String; price: Number; description: String; next_bill_date : string; } | any ;

  suscriptions = []

  constructor(private toast : ToastService,private dialog: MatDialog,private suscriptionService : SuscriptionsService) { }

  ngOnInit(): void {

    this.suscriptionService.suscriptions.subscribe( response =>{
      this.suscriptions = [];
      this.suscriptions.push(...response) ;
    }, err =>{
      console.log(err);
    })
  }

  onChangeStatus(){

  }

  onUpdate(sus : any){

    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateSuscriptionPopupComponent, {
      width: '100%',
      disableClose: true,
      data: { suscription : sus }
    })
    dialogRef.afterClosed()
      .subscribe(res => {

        if(res == 1){
          this.toast.showSuccess("Suscripción pausada correctamente");
        }else if(res == 2){
          this.toast.showSuccess("Suscripción cancelada correctamente");
        } else if(res == 0){
          this.toast.showSuccess("Suscripción activada correctamente");
        } else{
          this.suscriptionService.suscriptionUpdated.emit(false);
          return;
        }

        this.suscriptionService.suscriptionUpdated.emit(true);




      })

  }

}
