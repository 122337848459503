<!-- Navigation-->
<app-menu></app-menu>

<div id="page-top" [class]="class">
    <!-- Masthead-->

        <header class="masthead full coverim" id="inicio">

          <div class="container">
            <main class="form-signin">
              <h1 style="text-align: center" class="display-1 lh-1 mb-3">Inicia sesión</h1>
              <form  [formGroup]="form" (ngSubmit)="signin()" >
                <div style="margin-top: 10px;" class="form-floating">
                  <input [formControl]="form.controls['username']"  type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                  <label for="floatingInput">Correo</label>
                  <small
                    *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched"
                    class="form-error-msg"> Correo es requerido </small>
                </div>
                <div style="margin-top: 20px;" class="form-floating">
                  <input [formControl]="form.controls['password']" type="password" class="form-control" id="floatingPassword" placeholder="Password">
                  <label for="floatingPassword">Contraseña</label>
                  <small
                    *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched"
                    class="form-error-msg"> Contraseña es requerido </small>
                </div>
                <!-- <div class="checkbox mb-3">
                    <label style="margin-top: 20px;"><input type="checkbox" value="remember-me"> Remember me</label>
                </div>  -->
                <div style="margin-top: 20px; text-align: center" class="checkbox mb-3">
                  <a  (click)="onRegisterClick()" style="margin-top: 30px; cursor: pointer;"> Crear cuenta</a>
                </div>
                <div style="text-align: center" class="checkbox mb-3">
                  <a (click)="onforgotClick()" style="margin-top: 20px; cursor: pointer;"> Olvidé mi contraseña</a>
                </div>
                <div style="text-align: center">
                  <button class="btn btn-primary btn-xl " >Login</button>
                </div>
              </form>
            </main>
          </div>


    </header>

</div>
