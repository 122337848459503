<h2 mat-dialog-title>Crear tarjeta</h2>
<mat-dialog-content class="mat-typography">

  <form [formGroup]="form" >
    <div class="row g-3">

      <div class="col-sm-6">
        <label for="nameHolderCreditCard" class="form-label">Nombre</label>
        <input  [formControl]="form.controls['nameHolderCreditCard']" type="text" class="form-control one-c" name="nameHolderCreditCard" access="false" id="nameHolderCreditCard"  >
        <small
          *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched"
          class="form-error-msg"> Nombre del Titular es requerido </small>
      </div>

      <div class="col-sm-6">
        <label for="numberCreditCard" class="form-label">Número</label>
        <input  [formControl]="form.controls['numberCreditCard']" type="text" class="form-control one-c" name="numberCreditCard" access="false" id="numberCreditCard" >
        <small
          *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched"
          class="form-error-msg"> Número de Tarjeta es requerido </small>
      </div>

      <div class="col-sm-6">

        <label for="firstName" class="form-label">Mes expiración</label>
        <select [formControl]="form.controls['expMonthCreditCard']" class="form-control" name="mes-exp" id="mes-exp">
          <option disabled="" selected="" value="">Elije una opción </option>
          <option value="01" id="mes-exp-0">Enero</option>
          <option value="02" id="mes-exp-1">Febrero</option>
          <option value="03" id="mes-exp-2">Marzo</option>
          <option value="04" id="mes-exp-3">Abril</option>
          <option value="05" id="mes-exp-4">Mayo</option>
          <option value="06" id="mes-exp-5">Junio</option>
          <option value="07" id="mes-exp-6">Julio</option>
          <option value="08" id="mes-exp-7">Agosto</option>
          <option value="09" id="mes-exp-8">Septiembre</option>
          <option value="10" id="mes-exp-9">Octubre</option>
          <option value="11" id="mes-exp-10">Noviembre</option>
          <option value="12" id="mes-exp-11">Diciembre</option>
        </select>
        <small
          *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched"
          class="form-error-msg"> Mes es requerido </small>

      </div>

      <div class="col-sm-6">
        <label for="lastName" class="form-label">Año de expiración</label>
        <select [formControl]="form.controls['expYearCreditCard']"  class="form-control" name="ano-exp" id="ano-exp">
          <option disabled="" selected="" value="">Elije una opción</option>
          <option value="22" id="ano-exp-0">2022</option>
          <option value="23" id="ano-exp-1">2023</option>
          <option value="24" id="ano-exp-2">2024</option>
          <option value="25" id="ano-exp-3">2025</option>
          <option value="26" id="ano-exp-4">2026</option>
          <option value="27" id="ano-exp-5">2027</option>
          <option value="28" id="ano-exp-6">2028</option>
          <option value="29" id="ano-exp-7">2029</option>
          <option value="30" id="ano-exp-8">2030</option>
          <option value="31" id="ano-exp-9">2031</option>
          <option value="32" id="ano-exp-10">2032</option>
          <option value="33" id="ano-exp-11">2033</option>
          <option value="34" id="ano-exp-12">2034</option>
          <option value="35" id="ano-exp-13">2035</option>
          <option value="36" id="ano-exp-14">2036</option>
          <option value="37" id="ano-exp-15">2037</option>
          <option value="38" id="ano-exp-16">2038</option>
          <option value="39" id="ano-exp-17">2039</option>
          <option value="40" id="ano-exp-18">2040</option>
        </select>

        <small
          *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched"
          class="form-error-msg"> Año es requerido </small>
      </div>



      <!-- <div class="col-sm-4">
          <label for="phone" class="form-label">Teléfono</label>
           <input type="text" class="form-control" id="phone" placeholder="" value="" required="">

          <input style="color:black" style="width: 70%; margin-left: 85px; padding-left: 0px;"  (hasError)="hasError($event)"  (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event)" (ng2TelOutput)="getNumber($event)" [ng2TelInputOptions]="{initialCountry: 'pa',separateDialCode:true}" type="text" ng2TelInput />

          <small
          *ngIf="!errorNumber"
          class="form-error-msg"> Número de teléfono inválido </small>


      </div> -->

    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Volver</button>
  <button mat-button (click)="create()" cdkFocusInitial>Crear</button>
</mat-dialog-actions>
