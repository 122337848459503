<!-- Navigation-->
<app-menu *ngIf="showMenu"></app-menu>

<div *ngIf="!isActive" style="margin-top: 100px" class="fomr-checkout text-center">
  <h2>PORTAL EN MANTENIMIENTO</h2>
</div>

<div *ngIf="isActive" id="page-top" [class]="class" >

    <div class="container checkout">
        <main>

          <div *ngIf="!confirmation" style="margin-top: 100px" class="fomr-checkout text-center">
            <h2>Checkout form</h2>
          </div>

          <div *ngIf="confirmation"  style="margin-top: 100px" class="py-5 text-center">

            <h2>Confirmación</h2>
            <p class="lead">Felicidades, su suscripción ha sido recibida con el número #{{ hasPlanID }}. Recibirá un e-mail de confirmación en su dirección {{ emailCustomer }}</p>

            <a class="w-100 btn btn-primary btn-lg" routerLink="/suscriptions" >Ver mis suscripciones</a>
          </div>

          <div *ngIf="!confirmation" class="row g-5">
            <div class="col-md-5 col-lg-4 order-md-last">
              <h4 class="margin-boxs d-flex justify-content-between align-items-center mb-3">
                <span class="text-primary">Tu plan</span>
              </h4>
              <ul class="list-group mb-3">
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                   <!-- Portfolio item 3-->
                              <div class="portfolio-item box-plan">
                                  <a class="portfolio-link" data-bs-toggle="modal" >
                                      <img class="img-fluid" src="{{plan?.url_image}}" alt="..." />
                                  </a>
                                  <div class="portfolio-caption">
                                      <div class="portfolio-caption-heading">${{ plan?.feeCost_plan }}</div>
                                      <div class="portfolio-caption-subheading text-muted">{{ plan?.description }}</div>
                                                          </div>

                              </div>
                  </div>
                          </li>
                <li class="list-group-item d-flex justify-content-between">
                  <span>Total (USD)</span>
                  <strong>${{ plan?.feeCost_plan }}</strong>
                </li>
              </ul>

            </div>

            <div class="col-md-7 col-lg-8">
              <h4 class="mb-3">Datos de envío</h4>
              <a *ngIf="auth.isLoggedIn()" class="text-primary" (click)="addressesPopUp()" style="cursor: pointer;"> Mis direcciones  </a>
              <form [formGroup]="form" (ngSubmit)="checkout()" >
                <div class="row g-3">

                  <div *ngIf="!auth.isLoggedIn()" class="col-12">
                    <label for="address" class="form-label">Email</label>
                    <input [formControl]="form.controls['email']" type="text" class="form-control" id="email" placeholder="example@gmail.com" required="">
                    <small
                    *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                    class="form-error-msg"> Email es requerido </small>
                  </div>

                  <div class="col-sm-6">
                    <label for="firstName" class="form-label">Nombre</label>
                    <input [formControl]="form.controls['name']"  type="text" class="form-control" id="firstName" placeholder="" value="" required="">
                    <small
                      *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                      class="form-error-msg"> Nombre es requerido </small>
                  </div>

                  <div class="col-sm-6">
                    <label for="lastName" class="form-label">Apellidos</label>
                    <input [formControl]="form.controls['lastname']"  type="text" class="form-control" id="lastName" placeholder="" value="" required="">
                    <small
                      *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched"
                      class="form-error-msg"> Apellido es requerido </small>
                  </div>

                  <div class="col-12">
                    <label for="address" class="form-label">Dirección</label>
                    <input [formControl]="form.controls['address']" type="text" class="form-control" id="address" placeholder="Panamá" required="">
                    <small
                      *ngIf="form.controls['address'].hasError('required') && form.controls['address'].touched"
                      class="form-error-msg"> Dirección es requerido </small>
                  </div>

                  <div class="col-md-6">
                    <label for="firstName" class="form-label">Provincia</label>
                    <select  (change)="onProvinceChange()" [formControl]="form.controls['province']" class="form-control" name="mes-exp" id="mes-exp">
                      <option disabled="" selected="" value="">Elije una opción</option>
                      <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>

                    </select>
                    <small
                      *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched"
                      class="form-error-msg"> Provincia es requerido </small>
                  </div>

                  <div class="col-md-6">
                    <label for="lastName" class="form-label">Corregimiento</label>
                    <select [formControl]="form.controls['corregimiento']"  class="form-control" name="ano-exp" id="ano-exp">
                      <option disabled="" selected="" value="">Elije una opción</option>
                      <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>

                    </select>

                    <small
                      *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched"
                      class="form-error-msg"> Corregimiento es requerido </small>
                  </div>
                </div>

                <hr class="my-4">

                <h4 class="mb-3">Información de pago</h4>

                <a *ngIf="auth.isLoggedIn()" class="text-primary" (click)="tdcPopUp()" style="cursor: pointer;"> Mis tarjetas  </a>

                <div class="row gy-3">
                  <div class="col-md-6">
                    <label for="cc-name" class="form-label">Nombre de tarjeta</label>
                    <input [formControl]="form.controls['name_tdc']" type="text" class="form-control" id="cc-name" placeholder="" required="">
                    <small
                      *ngIf="form.controls['name_tdc'].hasError('required') && form.controls['name_tdc'].touched"
                      class="form-error-msg"> Nombre de tarjeta es requerido </small>
                  </div>

                  <div class="col-md-6">
                    <label for="cc-number" class="form-label">Número de tarjeta</label>
                    <input  (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['number_tdc']" type="text" class="form-control" id="cc-number" placeholder="" required="">
                    <small
                      *ngIf="form.controls['number_tdc'].hasError('required') && form.controls['number_tdc'].touched"
                      class="form-error-msg"> Número es requerido </small>
                  </div>

                  <div class="col-md-4">
                    <label for="firstName" class="form-label">Mes expiración</label>
                    <select [formControl]="form.controls['month']" class="form-control" name="mes-exp" id="mes-exp">
                      <option disabled="" selected="" value="">Elije una opción </option>
                      <option value="01" id="mes-exp-0">Enero</option>
                      <option value="02" id="mes-exp-1">Febrero</option>
                      <option value="03" id="mes-exp-2">Marzo</option>
                      <option value="04" id="mes-exp-3">Abril</option>
                      <option value="05" id="mes-exp-4">Mayo</option>
                      <option value="06" id="mes-exp-5">Junio</option>
                      <option value="07" id="mes-exp-6">Julio</option>
                      <option value="08" id="mes-exp-7">Agosto</option>
                      <option value="09" id="mes-exp-8">Septiembre</option>
                      <option value="10" id="mes-exp-9">Octubre</option>
                      <option value="11" id="mes-exp-10">Noviembre</option>
                      <option value="12" id="mes-exp-11">Diciembre</option>
                    </select>
                    <small
                      *ngIf="form.controls['month'].hasError('required') && form.controls['month'].touched"
                      class="form-error-msg"> Mes es requerido </small>
                  </div>

                  <div class="col-md-4">
                    <label for="lastName" class="form-label">Año de expiración</label>
                    <select [formControl]="form.controls['year']"  class="form-control" name="ano-exp" id="ano-exp">
                      <option disabled="" selected="" value="">Elije una opción</option>
                      <option value="22" id="ano-exp-0">2022</option>
                      <option value="23" id="ano-exp-1">2023</option>
                      <option value="24" id="ano-exp-2">2024</option>
                      <option value="25" id="ano-exp-3">2025</option>
                      <option value="26" id="ano-exp-4">2026</option>
                      <option value="27" id="ano-exp-5">2027</option>
                      <option value="28" id="ano-exp-6">2028</option>
                      <option value="29" id="ano-exp-7">2029</option>
                      <option value="30" id="ano-exp-8">2030</option>
                      <option value="31" id="ano-exp-9">2031</option>
                      <option value="32" id="ano-exp-10">2032</option>
                      <option value="33" id="ano-exp-11">2033</option>
                      <option value="34" id="ano-exp-12">2034</option>
                      <option value="35" id="ano-exp-13">2035</option>
                      <option value="36" id="ano-exp-14">2036</option>
                      <option value="37" id="ano-exp-15">2037</option>
                      <option value="38" id="ano-exp-16">2038</option>
                      <option value="39" id="ano-exp-17">2039</option>
                      <option value="40" id="ano-exp-18">2040</option>
                    </select>

                    <small
                      *ngIf="form.controls['year'].hasError('required') && form.controls['year'].touched"
                      class="form-error-msg"> Año es requerido </small>
                  </div>

                  <div class="col-md-4">
                    <label for="cc-cvv" class="form-label">CVV</label>
                    <input maxlength="3"  (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['ccv']" type="text" class="form-control" id="cc-cvv" placeholder="" required="">
                    <small
                      *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched"
                      class="form-error-msg"> CVV es requerido </small>
                  </div>
                </div>

                <hr class="my-4">

                <button class="w-100 btn btn-primary btn-lg" type="submit">Continuar con la suscripción</button>
              </form>
            </div>
          </div>
        </main>

        <footer class="my-5 pt-5 text-muted text-center text-small">
          <p class="mb-1">© 2017–2022 Company Name</p>
          <ul class="list-inline">
            <li class="list-inline-item"><a href="#">Privacy</a></li>
            <li class="list-inline-item"><a href="#">Terms</a></li>
            <li class="list-inline-item"><a href="#">Support</a></li>
          </ul>
        </footer>
      </div>

</div>
