
<div class="row">
  <div  *ngFor=" let plan of plans " class="col-lg-4 col-sm-6 mb-4">

    <div class="portfolio-item box-plan">
      <a class="portfolio-link" data-bs-toggle="modal">
        <img class="img-fluid" [src]="plan.getImage()" alt="..." />
      </a>
      <div class="portfolio-caption">
        <div class="portfolio-caption-heading">${{plan.getPrice()}} </div>
        <div class="portfolio-caption-subheading text-muted">{{ plan.getDescription() }}</div>
        <a  *ngIf="navigation" class="btn btn-primary " (click)="onSelectPlan(plan)" routerLink = "/checkout" >Lo quiero</a>
      </div>

    </div>

  </div>
</div>
