import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.css']
})
export class CreatePaymentComponent implements OnInit {

  form: any ;
  items: Array<any> = [];

  constructor(private auth : AuthService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CreatePaymentComponent>) {
    this.form = new FormGroup({

      nameHolderCreditCard : new FormControl('', Validators.required),
      numberCreditCard: new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', Validators.required),
      expYearCreditCard: new FormControl('', Validators.required),

    });
  }

  ngOnInit(): void {
  }

  create(){

    const obj = this.form.value;

    this.spinner.open();

    //Se verifica el rango del mes y ano de vencimiento de la TDC
    if (this.verifyCCYearAndMonth(obj.expMonthCreditCard.toString(),obj.expYearCreditCard.toString())) {
      //Se verifica el numero de digitos de la TDC
      if (obj.numberCreditCard.toString().length >= 13 && obj.numberCreditCard.toString().length <= 19) {
        //Se verifica que el numero de la TDC cumpla con el algoritmo de Luhm
        //if (this.valid_credit_card(obj.numberCreditCard.toString())) {
        //En caso de que el numero de tarjeta (ultimos digitos) coincidan con una TDC previamente agregada, pedir confirmacion

        obj.estatus = "1";
        obj.numberCreditCard = obj.numberCreditCard.toString();

        // HACEMOS ESTO PARA VALIDAR QUE NO ESTE INGRESANDO UNA TARJETA YA REGISTRADA
        let found = false;
        for(let tdc of this.items){
          if(tdc.maskCreditCard.substr(tdc.maskCreditCard.length-4) == obj.numberCreditCard.toString().substr(obj.numberCreditCard.toString().length-4) ){
            found = true;
          }
        }

        if(!found){
          //Se crea la TDC con todos los valores introducidos

          this.webService.post(obj,this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params => {
            this.toast.showSuccess("Creada correctamente");
            this.spinner.close();
            this.close();
          }, err => {

            //En caso de error al no reconocer el proveedor de la TDC
            if (err == "Credit card provider doesn't exist") {
              this.spinner.close();
              this.toast.showError("Proveedor de la tarjeta de crédito desconocido")
            }else{
              this.spinner.close();
              this.toast.showError("No se pudo crear tarjeta")
            }
          });
        }else{
          this.spinner.close();
          this.toast.showError("La tarjeta ya se encuentra registrada. ")
        }

      }
      else {
        this.spinner.close();
        this.toast.showError("Verifique el número de la tarjeta. Cantidad de digitos inválida");
      }
    }
    else {
      this.spinner.close();
      this.toast.showError("Verifique el año/mes de vencimiento de la tarjeta introducida");
    }

  }

  //Metodo que permite verificar el mes y ano de la TDC introducida
  verifyCCYearAndMonth(month : string, year: string) : boolean{
    //Verifica que el mes se encuentre entre 01 y 12
    if(1 < Number.parseInt(month) && Number.parseInt(month) > 12){
      return false;
    }

    //Verifica el limite inferior del ano de vencimiento
    if((Number.parseInt(month)-1) < new Date().getMonth()){
      if(Number.parseInt(year) <= Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }
    else{
      if(Number.parseInt(year) < Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }

    //Verifica el limite superior del ano de vencimiento
    if(Number.parseInt(year) >= (Number.parseInt(new Date().getFullYear().toString().substr(2))+15)){
      return false;
    }
    return true;
  }

  close(){
    this.dialogRef.close();
  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
