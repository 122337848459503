import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Plan } from 'src/app/components/plan/model/plan.model';
import { ImagesService } from 'src/app/services/image/images.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import { PlanService } from 'src/app/services/plan/plan.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  //plans = [{name :  "Plan 1", price : 15, description : "Descripción para el plan 1"}, {name :  "Plan 2", price : 25, description : "Descripción para el plan 2"}, {name :  "Plan 3", price : 35, description : "Descripción para el plan 3"}];

  plans : Plan[] = []
  // TENDRA VALORES POR DEFECTO EN CASO DE QUE NO SE ENCUENTRE NADA EN LA BUSQUEDA DE LOS DOMINIOS
  title : any = "";
  description : any = "";
  price_title : any = "";
  banner_title : any = "";
  banner_description : any = "";
  class : any = "pal1";
  imagePrincipal : any ;
  imagePromotional : any = null;
  isSuscriptionActive = true;
  aloudNavigation = true;


  constructor(private activateRoute : ActivatedRoute, private planService : PlanService, private imageService: ImagesService, private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {
    this.spinner.open();

    // dev-onboarding-contemporary.billcentric.com
    // window.location.host

    this.activateRoute.params.subscribe( params =>{
      let param = params.id != undefined ? params.id : -1;
      this.aloudNavigation = param == -1 ? true : false;
      if(!this.aloudNavigation){
        this.toast.showInfo("Este modo NO permite navegación")
      }
      // AQUI DEBEMOS VALIDAR LA URL POR LA QUE SE ESTA CONSULTADO EL PORTAL - SI ES VISTA PREVIA O el SITE COMPLETO
      // this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + "otroportalmas.billcentric.com" + "/"  + param).subscribe( response =>{
//  this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + "lovepinkve.billcentric.com" + "/"  + param).subscribe( response =>{ 
      //  this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + "laganga.billcentric.com" + "/"  + param).subscribe( response =>{ 
       this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/"  + param ).subscribe( response =>{
        this.showContentPage(response);
        this.spinner.close();
      }, err =>{
        this.spinner.close();
        this.toast.showError(err);
      });
    });
  }

  showContentPage(response: any){
    this.imagePrincipal = response.result.onboardingImages.principal_banner;
    if(response.result.customerHasPlan != null){
      var nexBillDate = new Date(response.result.customerHasPlan.next_bill_date);
      var today = new Date();
      var isValidDate = nexBillDate > today;
      if(response.result.customerHasPlan != null && (response.result.customerHasPlan.status_customerPlan !== 1 || !isValidDate)){
        this.isSuscriptionActive = false
      }
      this.planService.isSuscriptionActive.emit(this.isSuscriptionActive);
      this.show(response);      
    }else{
      this.show(response);
    }
  }

  show(response : any){
    for(let plan of response.result.plans){
      this.plans.push(new Plan(plan.name_plan, plan.feeCost_plan, plan.description, plan.url_image, plan.id, plan.service))
    }
    this.planService.obtainPlans.emit(this.plans);

    this.imagePrincipal = response.result.onboardingImages.principal_banner;
    this.imagePromotional = response.result.onboardingImages.promotional_banner
    localStorage.setItem("service", response.result.service.id);
    localStorage.setItem("pal",response.result.color); // GUARDAMOS LA CONFIGURACION DE COLORES QUE TIENE EL USUARIO
    localStorage.setItem("partner",response.result.partner.id); // GUARDAMOS EL PARTNER AL QUE PERTENECE EL TEMPLATE
    localStorage.setItem("step",response.result.service.current_onboarding_step); // GUARDAMOS EL STEP AL QUE PERTENECE EL TEMPLATE
    this.imageService.logoImage.emit(response.result.onboardingImages.logo);
    this.imageService.setPrincipalBannerImage(response.result.onboardingImages.principal_banner);
    this.class = response.result.color;
    this.title = response.result.onboardingPortalContent.page_title;
    this.description = response.result.onboardingPortalContent.description_page;
    this.price_title = response.result.onboardingPortalContent.price_title;
    this.banner_title = response.result.onboardingPortalContent.banner_title;
    this.banner_description = response.result.onboardingPortalContent.description_banner;
  }
  
  ngOnInit(): void {

    // this.spinner.open();
    // this.webService.get(this.webService.HOST + "/onboardingConfig/bydomain/" + window.location.host).subscribe( response =>{
    //   this.spinner.close();
    //
    //   this.class = response.result.color;
    //   this.title = response.result.onboardingPortalContent.page_title;
    //   this.description = response.result.onboardingPortalContent.description_page;
    //   this.price_title = response.result.onboardingPortalContent.price_title;
    //   this.banner_title = response.result.onboardingPortalContent.banner_title;
    //   this.banner_description = response.result.onboardingPortalContent.description_banner;
    //
    //
    // }, err =>{
    //   this.spinner.close();
    //   this.toast.showError(err);
    // })


  }

  getImagePrincipal(){
    return this.imagePrincipal ;
  }

  getImagePromotional(){
    return this.imagePromotional;
  }

}
