import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component'
import { AddressesComponent } from './views/addresses/addresses.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ForgotComponent } from './views/forgot/forgot.component';
import { PaymentsComponent } from './views/payments/payments.component';
import { ProfileComponent } from './views/profile/profile.component';
import { SingupComponent } from './views/singup/singup.component';
import { AuthGuard } from './guards/auth.guard';
import { SuscriptionsComponent } from './views/suscriptions/suscriptions.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'preview/:id', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'singup', component: SingupComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'profile',  canActivate:  [AuthGuard], component: ProfileComponent },
  { path: 'payments',  canActivate:  [AuthGuard], component: PaymentsComponent },
  { path: 'suscriptions',  canActivate:  [AuthGuard], component: SuscriptionsComponent },
  { path: 'addresses',  canActivate:  [AuthGuard], component: AddressesComponent },
  { path: 'change-password',  canActivate:  [AuthGuard], component: ChangePasswordComponent },
  { path: 'checkout', component: CheckoutComponent, data: { checkout: true} },
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
