<div id="page-top" [class]="class">
  <!-- Masthead-->

  <header class="masthead full coverim" id="inicio">

    <div class="container">
      <main class="form-signin">
        <h1 style="text-align: center" class="display-1 lh-1 mb-3">Recupera tu Contraseña</h1>
        <p style="text-align: center" class="sites-description">Te enviaremos un correo con tu nueva contraseña</p>
        <form  [formGroup]="form" (ngSubmit)="reset()" >
          <div style="margin-top: 10px;" class="form-floating">
            <input [formControl]="form.controls['email']"  type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
            <label for="floatingInput">Correo</label>
            <small
              *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
              class="form-error-msg"> Correo es requerido </small>
          </div>
          <div style="margin-top: 20px; text-align: center" class="checkbox mb-3">
            <a  (click)="onRegisterClick()" style="margin-top: 30px; cursor: pointer;"> Crear cuenta</a>
          </div>
          <div style="text-align: center">
            <button class="btn btn-primary btn-xl " >Recuperar</button>
          </div>

        </form>
      </main>
    </div>


  </header>

</div>
