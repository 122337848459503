<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
    <div class="container">
        <a class="navbar-brand" routerLink="/"><img [src]="logoImage" alt="..." /></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars ms-1"></i>
        </button>
        <div *ngIf="navigation" class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ms-auto py-4 py-lg-0">
                <li class="nav-item"><a class="nav-link" routerLink="/">Inicio</a></li>
                <li *ngIf="!auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/login">Login</a></li>
                <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/suscriptions">Suscripciones</a></li>
                <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/addresses">Direcciones</a></li>
                <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/payments">Tarjetas</a></li>
                <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/change-password">Cambiar contraseña</a></li>
                <li *ngIf="auth.isLoggedIn()" class="nav-item"><a style="cursor: pointer;" (click)="logout()" class="nav-link" >Salir</a></li>
            </ul>
        </div>
    </div>
</nav>

